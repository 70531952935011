import React from "react";

import HeadSEO from "./head-seo";
import Header from "./header";
import Footer from "./footer";

const Layout = ({ site = {}, page = {}, schema, children }) => {
  return (
    <>
      <HeadSEO site={site} page={page} schema={schema} />
      <Header
        data={site.header}
        social={site.footer?.social}
        isTransparent={page.hasTransparentHeader}
        mobileOverlay={page?.mobileOverlay}
        desktopOverlay={page?.desktopOverlay}
      />
      <main id="content">{children}</main>
      <Footer data={site.footer} />
    </>
  );
};

export default Layout;
