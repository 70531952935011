import Link from "next/link";
import PropTypes from "prop-types";
import { UilEnvelope } from "@iconscout/react-unicons";

import { getPageLink, getPageTo } from "components/link";
import SocialIcons from "components/social-icons";
import LottieImage from "components/lottie-image";
import Logo from "components/logo";
import colors from "utils/colors";

const Column = ({ title, items }) => {
  return (
    <>
      <h3 className="text-md font-bold">{title}</h3>
      <ul role="list" className="mt-4 space-y-2">
        {items?.map((item) => {
          let to = (item.target?.isInternal ? getPageLink(item.target.page) : item.target?.url) || "/";
          to = getPageTo(to);
          return (
            <li key={item.label}>
              <Link href={to} as={to} passHref>
                <a className="hover:text-gray-900 cursor-pointer text-sm text-blacktext hover:underline">
                  {item.label}
                </a>
              </Link>
            </li>
          );
        })}
      </ul>
    </>
  );
};

const Row = ({ title, items }) => {
  return (
    <>
      <div className="mb-2 block w-full text-center text-md font-normal">{title}</div>
      <ul role="list" className=" text-center">
        {items?.map((item) => {
          let to = (item.target?.isInternal ? getPageLink(item.target.page) : item.target?.url) || "/";
          to = getPageTo(to);
          return (
            <li key={item.label} className="mr-4 mb-2 inline-block">
              <Link href={to} as={to} passHref>
                <a className="hover:text-gray-900 cursor-pointer text-sm  text-primary-400 hover:underline">
                  {item.label}
                </a>
              </Link>
            </li>
          );
        })}
      </ul>
    </>
  );
};

Row.propTypes = {
  title: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      target: PropTypes.shape({
        internal: PropTypes.shape({
          slug: PropTypes.shape({
            current: PropTypes.string,
          }),
        }),
      }),
    })
  ),
};

Column.propTypes = {
  title: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      target: PropTypes.shape({
        internal: PropTypes.shape({
          slug: PropTypes.shape({
            current: PropTypes.string,
          }),
        }),
      }),
    })
  ),
};

const Footer = ({ data }) => {
  const {
    blockMenu1,
    blockTitle1,
    blockCopy,
    blockMenu2,
    blockTitle2,
    blockMenu3,
    blockTitle3,
    blockMenu4,
    blockTitle4,
    blockTitle5,
    blockMenu5,
    social,
    Image,
    lottieFile,
  } = data;
  return (
    <footer className="foot flex flex-grow items-center justify-center border-t border-[#ddd]">
      <section className="mx-auto mt-6 flex w-full flex-col px-1 sm:px-12 md:mt-12 md:max-w-[1280px] md:flex-row md:justify-between  md:px-12 md:pt-12 lg:max-w-[1680px] lg:px-24">
        <h2 id="footer-heading" className="sr-only">
          Footer
        </h2>
        <div className="max-w-8xl mx-auto w-full px-4 py-12 sm:px-0 md:py-0 lg:px-0">
          <div className="flex grid grid-cols-2 justify-center gap-8 md:grid-cols-7">
            <div className="col-span-2 md:col-span-3 md:pr-24 lg:pr-48">
              <Logo color={colors.brown} />
              <p className="mt-5 pr-5 text-sm font-normal leading-6 ">{blockCopy}</p>
              <article className="mb-12 flex-1 sm:mb-0">
                <LottieImage image={Image} lottieFile={lottieFile} width={150} height={150} />
              </article>
            </div>
            <div className="md:col-span-1 ">
              <Column title={blockTitle1} items={blockMenu1?.items} />
            </div>
            <div className="md:col-span-1 ">
              <Column title={blockTitle2} items={blockMenu2?.items} />
            </div>

            <div className="md:col-span-1">
              <Column title={blockTitle3} items={blockMenu3?.items} />
            </div>
            <div className="md:col-span-1">
              <Column title={blockTitle4} items={blockMenu4?.items} />
            </div>
          </div>
          <div className="mt-10">
            <Row title={blockTitle5} items={blockMenu5?.items} />
          </div>

          <div className="border-1 mt-10 mb-2 items-center border-t border-[#ddd] pb-4  pt-6 text-sm font-normal md:mt-20 md:flex md:items-center md:justify-between">
            <div className="flex space-x-2">
              <p className="items-center pb-8 text-center text-sm  md:pb-0">
                &copy; {new Date().getFullYear()} Build Appeal, Inc.
              </p>
              <span className=" pb-8 text-lg leading-4  md:pb-0">·</span>
              <a
                href="https://buildappeal.com/terms-of-service"
                className="hover:text-gray-100 cursor-pointer pb-8 text-center  hover:underline md:pb-0"
              >
                Terms
              </a>
              <span className=" pb-8 text-lg leading-4 md:pb-0">·</span>
              <a
                href="https://buildappeal.com/privacy-policy"
                className="hover:text-gray-100 cursor-pointer pb-8 text-center  hover:underline md:pb-0"
              >
                Privacy
              </a>
            </div>
            <div className="flex items-center space-x-6 md:justify-center">
              {social?.map((item) => (
                <a key={item.icon} href={item.url} target="_blank" rel="noreferrer" className=" hover:text-gray-500">
                  <span className="sr-only">{item.icon}</span>
                  <SocialIcons icon={item.icon} className="h-5 w-5" aria-hidden="true" />
                </a>
              ))}
              <span className="ml-2 hidden md:flex">
                {" "}
                <UilEnvelope className="mr-2 inline-block" aria-hidden="true" />
                hi@buildappeal.com
              </span>
            </div>
          </div>
        </div>
      </section>
    </footer>
  );
};

Footer.propTypes = {
  blockMenu1: PropTypes.shape(Column.propTypes),
  blockMenu2: PropTypes.shape(Column.propTypes),
  blockMenu3: PropTypes.shape(Column.propTypes),
  blockMenu4: PropTypes.shape(Column.propTypes),
  blockTitle1: PropTypes.string,
  blockTitle2: PropTypes.string,
  blockTitle3: PropTypes.string,
  blockTitle4: PropTypes.string,
  blockTitle5: PropTypes.string,
  social: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string,
      url: PropTypes.string,
    })
  ),
};

export default Footer;
